<template>
  <div id="sec-three-left">
    <div class="table-container">
      <div class="table-haader">Стандартынй коплект</div>
      <div class="table-table">
        <div class="table-row">
          <div class="table-column table-column">Наименование</div>
          <div class="table-column">Количество</div>
          <div class="table-column">Стоимость</div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">Охранная панель</div>
          <div class="table-column">1</div>
          <div class="table-column">-</div>
        </div>
        <div
          class="table-row"
          v-if="this.GETDATA.securityType != 'secureButton'"
        >
          <div class="table-column table-column-title">
            Датчик открытия двери
          </div>
          <div class="table-column">1</div>
          <div class="table-column">-</div>
        </div>
        <div
          class="table-row"
          v-if="this.GETDATA.securityType != 'secureButton'"
        >
          <div class="table-column table-column-title">
            Датчик движения (с защитой от животных)
          </div>
          <div class="table-column">1</div>
          <div class="table-column">-</div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">
            Брелок управления (+ тревожная кнопка)
          </div>
          <div class="table-column">2</div>
          <div class="table-column">-</div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">
            Блок питания (+ резервный источник)
          </div>
          <div class="table-column">1</div>
          <div class="table-column">-</div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">Сирена</div>
          <div class="table-column">1</div>
          <div class="table-column">-</div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">
            Табличка "Объект под охраной"
          </div>
          <div class="table-column">1</div>
          <div class="table-column">-</div>
        </div>
      </div>
      <div class="table-result">
        Итого стоимость комплекта:
        {{ basePrice == 0 ? 0 : addDot(basePrice) }}₽.
      </div>
    </div>

    <div class="table-container">
      <div class="table-haader">Дополнительное оборудование</div>
      <div class="table-table">
        <div class="table-row">
          <div class="table-column table-column-title">Датчик движения</div>
          <div class="table-column table-column-control">
            <div class="sub" @click="SUB('moveDetectorsQuantity')"></div>
            <div class="quantity">{{ GETDATA.moveDetectorsQuantity }}</div>
            <div class="add" @click="ADD('moveDetectorsQuantity')"></div>
          </div>
          <div class="table-column">{{ addDot(GETPRICIES.moveDetector) }}₽</div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">
            Датчик движения (с защитой от животных)
          </div>
          <div class="table-column table-column-control">
            <div class="sub" @click="SUB('moveDetectorSecuresQuantity')"></div>
            <div class="quantity">
              {{ GETDATA.moveDetectorSecuresQuantity }}
            </div>
            <div class="add" @click="ADD('moveDetectorSecuresQuantity')"></div>
          </div>
          <div class="table-column">
            {{ addDot(GETPRICIES.moveDetectorSecure) }}₽
          </div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">
            Датчик открытия двери
          </div>
          <div class="table-column table-column-control">
            <div class="sub" @click="SUB('doreOpeningDetectorsQuantity')"></div>
            <div class="quantity">
              {{ GETDATA.doreOpeningDetectorsQuantity }}
            </div>
            <div class="add" @click="ADD('doreOpeningDetectorsQuantity')"></div>
          </div>
          <div class="table-column">
            {{ addDot(GETPRICIES.doreOpeningDetector) }}₽
          </div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">
            Брелок управления (+ тревожная кнопка)
          </div>
          <div class="table-column table-column-control">
            <div class="sub" @click="SUB('controlPanelsQuantity')"></div>
            <div class="quantity">{{ GETDATA.controlPanelsQuantity }}</div>
            <div class="add" @click="ADD('controlPanelsQuantity')"></div>
          </div>
          <div class="table-column">{{ addDot(GETPRICIES.controlPanel) }}₽</div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">
            Станционарная тревожная кнопка
          </div>
          <div class="table-column table-column-control">
            <div class="sub" @click="SUB('stationarPanelsQuantity')"></div>
            <div class="quantity">{{ GETDATA.stationarPanelsQuantity }}</div>
            <div class="add" @click="ADD('stationarPanelsQuantity')"></div>
          </div>
          <div class="table-column">
            {{ addDot(GETPRICIES.stationarPanel) }}₽
          </div>
        </div>

        <div class="table-row">
          <div class="table-column table-column-title">Видеонаблюдение</div>
          <div class="table-column table-column-control table-column-toggle">
            <div
              class="toggle"
              :class="{ off: !GETDATA.videoMonitoring }"
              @click="ADD('videoMonitoring')"
            ></div>
          </div>
        </div>

        <div class="table-row">
          <div class="table-column table-column-title">Датчик дыма</div>
          <div class="table-column table-column-control">
            <div class="sub" @click="SUB('smokeDetectorsQuantity')"></div>
            <div class="quantity">
              {{
                GETDATA.smokeDetectorsQuantity == null
                  ? 0
                  : GETDATA.smokeDetectorsQuantity
              }}
            </div>
            <div class="add" @click="ADD('smokeDetectorsQuantity')"></div>
          </div>
          <div class="table-column">
            {{ addDot(GETPRICIES.smokeDetector) }}₽
          </div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">
            Датчик протечки воды
          </div>
          <div class="table-column table-column-control">
            <div class="sub" @click="SUB('waterDetectorsQuantity')"></div>
            <div class="quantity">
              {{
                GETDATA.waterDetectorsQuantity == null
                  ? 0
                  : GETDATA.waterDetectorsQuantity
              }}
            </div>
            <div class="add" @click="ADD('waterDetectorsQuantity')"></div>
          </div>
          <div class="table-column">
            {{ addDot(GETPRICIES.waterDetector) }}₽
          </div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">Датчик утечки газа</div>
          <div class="table-column table-column-control">
            <div class="sub" @click="SUB('gasDetectorsQuantity')"></div>
            <div class="quantity">
              {{
                GETDATA.gasDetectorsQuantity == null
                  ? 0
                  : GETDATA.gasDetectorsQuantity
              }}
            </div>
            <div class="add" @click="ADD('gasDetectorsQuantity')"></div>
          </div>
          <div class="table-column">{{ addDot(GETPRICIES.gasDetector) }}₽</div>
        </div>
        <div class="table-row">
          <div class="table-column table-column-title">
            Выносной термодатчик
          </div>
          <div class="table-column table-column-control">
            <div class="sub" @click="SUB('termometersQuantity')"></div>
            <div class="quantity">{{ GETDATA.termometersQuantity }}</div>
            <div class="add" @click="ADD('termometersQuantity')"></div>
          </div>
          <div class="table-column">{{ addDot(GETPRICIES.termometer) }}₽</div>
        </div>

        <div class="table-result">Итого стоимость доп. оборудования: {{cash - basePrice == 0 ? 0 : addDot(cash - basePrice)}}₽</div>

      </div>
      <div class="table-result table-result-all">
        Итого стоимость комплекта: {{ cash == 0 ? 0 : addDot(cash) }}₽
      </div>
      <div class="table-result table-result-add" v-if="GETDATA.videoMonitoring">
        (стоимость видеонаблюдения расчитываеться специалистом) +
        Видеонаблюдение
      </div>
      <div class="table-result table-result-all">
        Ежемесячная абонентская плата: {{ addDot(renting) }}₽
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "sec-three-left",
  computed: {
    ...mapGetters(["GETDATA", "GETPRICIES"]),
    cash() {
      let cash = 0;

      for (let i in this.GETPRICIES) {
        cash += this.GETPRICIES[i] * this.GETDATA[i + "sQuantity"];
      }

      cash += this.basePrice;
      return cash;
    },
    renting() {
      return this.GETDATA.securityType == "secureComplex" ? 3500 : 2900;
    },
    basePrice() {
      let cash = this.GETDATA.securityType == "secureButton" ? 7500 : 9900;

      if (
        this.GETDATA.securityType == "secureButton" &&
        this.GETDATA.securityButtonType == "app"
      ) {
        cash = 0;
      }
      return cash;
    }
  },
  methods: {
    ...mapMutations(["SETDATA", "ADD", "SUB"]),
    addDot(digit) {
      let i = [];
      while (digit >= 1) {
        i.unshift(digit % 1000);
        digit = Math.floor(digit / 1000);
      }
      for (let j = 1; j < i.length; j++) {
        i[j] = String(i[j]);
        while (i[j].length < 3) {
          i[j] = "0" + i[j];
        }
      }

      return i.join(",");
    }
  }
};
</script>

<style lang="scss">
#sec-three-left {
  .off {
    filter: grayscale(10);
    opacity: 0.6;
    transform: scale(-1, 1);
  }

  .table-container {
    .table-haader {
      text-align: center;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .table-table {
      .table-row {
        width: 100%;
        display: grid;
        grid-template-columns: 55% 23.5% 21.5%;
        border-left: solid 1px black;
        text-align: center;

        .table-column-title {
          text-align: left;
        }

        .table-column {
          padding: 1px 5px 1px 5px;
          font-size: 11px;
          border-bottom: solid 1px black;
          border-right: solid 1px black;
        }

        .table-column-control {
          padding: 1px 0 1px 0;
          display: grid;
          grid-template-columns: 1fr 20px 1fr;
          grid-gap: 2px;
          justify-items: center;
          align-items: center;

          .sub,
          .add {
            width: 15px;
            height: 15px;
            background-position: center;
            background-size: 100% auto;
            background-repeat: no-repeat;
            cursor: pointer;
          }

          .sub {
            background-image: url("~@/assets/img/sub.svg");
          }

          .add {
            background-image: url("~@/assets/img/add.svg");
          }
        }

        .table-column-toggle {
          grid-column-start: 2;
          grid-column-end: 4;

          .toggle {
            width: 35px;
            height: 15px;
            background-position: center;
            background-size: 100% auto;
            background-repeat: no-repeat;
            cursor: pointer;
            background-image: url("~@/assets/img/toggle.svg");
            grid-column-start: 1;
            grid-column-end: 4;
          }
        }
      }
    }
    .table-result {
      font-size: 12px;
      text-align: right;
      margin: 5px 30px 5px auto;
    }

    .table-result-all {
      font-weight: 700;
      font-size: 14px;
    }

    .table-result-add {
      font-size: 9px;
      border-bottom: 1px solid #000;
      padding-bottom: 1px;
      margin-right: 0;
      padding-right: 30px;
    }
  }
}
</style>
